export const userData = [
    {
      name: "Jan",
      "Total Users": 4000,
      "Total Videos": 300,
      "Videos Viewed": 1450,
      "Ads played": 1280,
    },
    {
      name: "Feb",
      "Total Users": 3000,
      "Total Videos": 360,
      "Videos Viewed": 2100,
      "Ads played": 1080,
    },
    {
      name: "Mar",
      "Total Users": 5000,
      "Total Videos": 310,
      "Videos Viewed": 3100,
      "Ads played": 2280,
    },
    {
      name: "Apr",
      "Total Users": 4000,
      "Total Videos": 200,
      "Videos Viewed": 1900,
      "Ads played": 800,
    },
    {
      name: "May",
      "Total Users": 3000,
      "Total Videos": 100,
      "Videos Viewed": 3100,
      "Ads played": 380,
    },
    {
      name: "Jun",
      "Total Users": 2000,
      "Total Videos": 300,
      "Videos Viewed": 1600,
      "Ads played": 850,
    },
    {
      name: "Jul",
      "Total Users": 4000,
      "Total Videos": 360,
      "Videos Viewed": 4370,
      "Ads played": 80,
    },
    {
      name: "Aug",
      "Total Users": 3000,
      "Total Videos": 390,
      "Videos Viewed": 3100,
      "Ads played": 1180,
    },
    {
      name: "Sep",
      "Total Users": 4000,
      "Total Videos": 500,
      "Videos Viewed": 4100,
      "Ads played": 840,
    },
    {
      name: "Oct",
      "Total Users": 1000,
      "Total Videos": 530,
      "Videos Viewed": 4300,
      "Ads played": 2280,
    },
    {
      name: "Nov",
      "Total Users": 4000,
      "Total Videos": 600,
      "Videos Viewed": 6500,
      "Ads played": 3380,
    },
    {
      name: "Dec",
      "Total Users": 3000,
      "Total Videos": 530,
      "Videos Viewed": 4100,
      "Ads played": 3380,
    },
  ];

  export const toptenData = [
    { id: 1, name: 'Avengers', views: 9000, rating: 4.5 },
    { id: 2, name: 'Superman', views: 6456, rating: 3.5 },
    { id: 3, name: 'Spiderman', views: 4586, rating: 4.3 },
    { id: 4, name: 'HeMan', views: 2356, rating: 4.2 },
    { id: 5, name: 'Batman', views: 8454, rating: 4.1 },
    { id: 6, name: 'Shakthiman', views: 3454, rating: 5.0 },
    { id: 7, name: 'Ironman', views: 8453, rating: 2.5 },
    { id: 8, name: 'Rockman', views: 2134, rating: 3.2 },
    { id: 9, name: 'Windman', views: 8674, rating: 4.8 },
    { id: 10, name: 'Human', views: 1655, rating: 5.0 },
  ];

  export const tagsData = ["Rain", "Plant", "Smoke", "Pollution", "Fire", "Microbes"];
  export const genresData = ["Action", "Comedy", "Drama", "Romance", "Thriller", "Documentary"];

  export const ser_sea_data = [
    {"series":"-","season":"-"},
    {series: "Series 1", season: "Season 1"},
    {series: "Series 1", season: "Season 4"},
    {series: "Series 1", season: "Season 5"},
    {series: "Series 1", season: "A very long season name called: Season 3"},
    {series: "Series 1", season: "Season 2"},
    {series: "Series 2", season: "Season 2"},
    {series: "Series 2", season: "Season 6"},
    {series: "Series 2", season: "Season 4"},
    {series: "Series 2", season: "Season 5"},
    {series: "Series 2", season: "Season 3"},
    {series: "Series 2", season: "Season 1"},
    {series: "Series 3", season: "Season 2"},
    {series: "Series 3", season: "Season 1"},
  ];

  export const videoData = [
    {
      name: "Jan",
      "Views": 2000,
    },
    {
      name: "Feb",
      "Views": 4000,
    },
    {
      name: "Mar",
      "Views": 3000,
    },
    {
      name: "Apr",
      "Views": 4000,
    },
    {
      name: "May",
      "Views": 4500,
    },
    {
      name: "Jun",
      "Views": 5400,
    },
    {
      name: "Jul",
      "Views": 4500,
    },
    {
      name: "Aug",
      "Views": 6000,
    },
  ];

  export const seriesData = [
    {
      id: 'SR1',
      series_name: "Series Name 1",
      series_desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      series_seasons: ['Season1', 'Season 2', 'Season 3']
    },
    {
      id: 'SR2',
      series_name: "Series Name 2",
      series_desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      series_seasons: ['Season 1', 'Season 2', 'Season 3', 'Season 4']
    },
    {
      id: 'SR3',
      series_name: "Series Name 3",
      series_desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      series_seasons: ['Season 1', 'Season 2']
    },
    {
      id: 'SR4',
      series_name: "Series Name 4",
      series_desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      series_seasons: ['Season 1']
    },
    {
      id: 'SR5',
      series_name: "Series Name 5",
      series_desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      series_seasons: ['Season 1', 'Season 2', 'Season 3']
    },
  ];

  export const userRows = [
    {
      id: 1,
      username: "Jon Snow",
      avatar:
        "https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      email: "jon@gmail.com",
      currentplan: "Basic plan",
      status: "Active",
      latestlogin: "2022-02-23",
    },
    {
      id: 2,
      username: "Jon Snow",
      avatar:
        "https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      email: "jon@gmail.com",
      currentplan: "Free user",
      status: "InActive",
      latestlogin: "2022-02-23",
    },
    {
      id: 3,
      username: "Jon Snow",
      avatar:
        "https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      email: "jon@gmail.com",
      currentplan: "Plus plan",
      status: "Active",
      latestlogin: "2022-02-23",
    },
    {
      id: 4,
      username: "Jon Snow",
      avatar:
        "https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      email: "jon@gmail.com",
      currentplan: "Premium plan",
      status: "Active",
      latestlogin: "2022-02-23",
    },
    {
      id: 5,
      username: "Jon Snow",
      avatar:
        "https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      email: "jon@gmail.com",
      currentplan: "Free user",
      status: "Active",
      latestlogin: "2022-02-23",
    }
  ];

  export const productRows = [
    {
      id: 1,
      name: "Apple Airpods",
      img:
        "https://images.pexels.com/photos/7156886/pexels-photo-7156886.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      stock: 123,
      status: "active",
      price: "$120.00",
    },
  ];

  export const cmsData = [
      {
        "id": 936917,
        "channel_id": 18,
        "status": 1,
        "name": "Rain Plants",
        "description": "This is a demo description for the Rain Plants video clip.",
        "category": "Movie",
        "ser_sea": { "series": '-', "season": '-' },
        "series": "-",
        "season": "-",
        "epi_num": 0,
        "featured": false,
        "tags": ["Rain", "Plant"],
        "genre": ["Drama", "Romance"],
        "total_rating": 315,
        "numberof_ratings": 70,
        "total_views": 200,
        "partner_name": "Sub Client 1",
        "thumbnail": "//cdn.brid.tv/live/partners/22479/thumb/936917_t_1641529350.png",
        "thumb": "http://cdn.brid.tv/live/partners/22479/thumb/936917_t_1641529350.png",
        "image": "//cdn.brid.tv/live/partners/22479/snapshot/936917.png",
        "webp": "//cdn.brid.tv/live/partners/22479/webp/936917.webp",
        "source": {
          "ld": "//cdn.brid.tv/live/partners/22479/ld/936917.mp4",
          "sd": "//cdn.brid.tv/live/partners/22479/sd/936917.mp4",
          "hsd": "//cdn.brid.tv/live/partners/22479/hsd/936917.mp4",
          "hd": "//cdn.brid.tv/live/partners/22479/hd/936917.mp4"
        },
        "duration": 30,
        "width": 1920,
        "height": 1080,
        "publish_time": "00:00:00",
        "publish": "2022-01-07",
        "age_gate_id": 1,
      },
      {
        "id": 936916,
        "channel_id": 18,
        "status": 0,
        "name": "FireworksSlowMotion",
        "category": "ShortFilm",
        "ser_sea": { "series": '-', "season": '-' },
        "series": "-",
        "season": "-",
        "epi_num": 0,
        "featured": false,
        "tags": ["Fire", "Pollution"],
        "genre": ["Action", "Thriller"],
        "total_rating": 200,
        "numberof_ratings": 50,
        "total_views": 400,
        "partner_name": "Sub Client 2",
        "thumbnail": "//cdn.brid.tv/live/partners/22479/thumb/936916_t_1641529313.png",
        "image": "//cdn.brid.tv/live/partners/22479/snapshot/936916.png",
        "source": {
          "ld": "//cdn.brid.tv/live/partners/22479/ld/936916.mp4",
          "sd": "//cdn.brid.tv/live/partners/22479/sd/936916.mp4",
          "hsd": "//cdn.brid.tv/live/partners/22479/hsd/936916.mp4",
          "hd": "//cdn.brid.tv/live/partners/22479/hd/936916.mp4"
        },
        "duration": 63,
        "width": 1920,
        "height": 1080,
        "publish_time": "00:00:00",
        "publish": "2022-01-07",
        "age_gate_id": 1,
        "webp": "//cdn.brid.tv/live/partners/22479/webp/936916.webp",
        "thumb": "http://cdn.brid.tv/live/partners/22479/thumb/936916_t_1641529313.png",
      },
      {
        "id": 936915,
        "channel_id": 18,
        "status": 1,
        "name": "FreeSmokeYoutube",
        "category": "UnAssorted",
        "ser_sea": { "series": '-', "season": '-' },
        "series": "-",
        "season": "-",
        "epi_num": 0,
        "featured": true,
        "tags": [],
        "genre": [],
        "total_rating": 210,
        "numberof_ratings": 70,
        "total_views": 200,
        "partner_name": "Sub Client 1",
        "thumbnail": "//cdn.brid.tv/live/partners/22479/thumb/936915_t_1641529303.png",
        "image": "//cdn.brid.tv/live/partners/22479/snapshot/936915.png",
        "source": {
          "ld": "//cdn.brid.tv/live/partners/22479/ld/936915.mp4",
          "sd": "//cdn.brid.tv/live/partners/22479/sd/936915.mp4"
        },
        "duration": 15,
        "publish_time": "00:00:00",
        "publish": "2022-01-07",
        "age_gate_id": 1,
        "webp": "//cdn.brid.tv/live/partners/22479/webp/936915.webp",
        "thumb": "http://cdn.brid.tv/live/partners/22479/thumb/936915_t_1641529303.png",
      },
      {
        "id": 935080,
        "channel_id": 121,
        "status": 0,
        "name": "Microscope Sample",
        "description": "Testing using Microscope",
        "category": "Series",
        "ser_sea": { "series": 'Series 1', "season": 'Season 3' },
        "series": "Series 1",
        "season": "Season 3",
        "epi_num": 2,
        "featured": false,
        "tags": ["Pollution", "Microbes"],
        "genre": ["Drama", "Documentary"],
        "total_rating": 245,
        "numberof_ratings": 70,
        "total_views": 200,
        "partner_name": "Sub Client 1",
        "thumbnail": "//cdn.brid.tv/live/partners/22479/thumb/935080_t_1641231343.png",
        "image": "//cdn.brid.tv/live/partners/22479/snapshot/935080.png",
        "source": {
          "ld": "//cdn.brid.tv/live/partners/22479/ld/935080.mp4",
          "sd": "//cdn.brid.tv/live/partners/22479/sd/935080.mp4",
          "hsd": "//cdn.brid.tv/live/partners/22479/hsd/935080.mp4",
          "hd": "//cdn.brid.tv/live/partners/22479/hd/935080.mp4"
        },
        "duration": 17,
        "width": 1920,
        "height": 1080,
        "publish_time": "00:00:00",
        "publish": "2022-01-03",
        "age_gate_id": 1,
        "webp": "//cdn.brid.tv/live/partners/22479/webp/935080.webp",
        "thumb": "http://cdn.brid.tv/live/partners/22479/thumb/935080_t_1641231343.png",
      }
    ]

  export const bridtvData = {
      "data": [
        {
          "Video": {
            "id": 936917,
            "channel_id": 18,
            "ads": [
              []
            ],
            "encoded": true,
            "status": 1,
            "name": "Rain Plants",
            "description": "This is a demo description for the Rain Plants video clip.",
            "thumbnail": "//cdn.brid.tv/live/partners/22479/thumb/936917_t_1641529350.png",
            "image": "//cdn.brid.tv/live/partners/22479/snapshot/936917.png",
            "source": {
              "ld": "//cdn.brid.tv/live/partners/22479/ld/936917.mp4",
              "sd": "//cdn.brid.tv/live/partners/22479/sd/936917.mp4",
              "hsd": "//cdn.brid.tv/live/partners/22479/hsd/936917.mp4",
              "hd": "//cdn.brid.tv/live/partners/22479/hd/936917.mp4"
            },
            "duration": 30,
            "width": 1920,
            "height": 1080,
            "monetize": true,
            "publish_time": "00:00:00",
            "publish": "2022-01-07",
            "age_gate_id": 1,
            "webp": "//cdn.brid.tv/live/partners/22479/webp/936917.webp",
            "liveimage": true,
            "thumb": "http://cdn.brid.tv/live/partners/22479/thumb/936917_t_1641529350.png",
            "tracks": [
              {
                "kind": "storyboard",
                "src": "//cdn.brid.tv/live/partners/22479/vtt/936917.vtt"
              }
            ]
          }
        },
        {
          "Video": {
            "id": 936916,
            "channel_id": 18,
            "ads": [
              []
            ],
            "encoded": true,
            "status": 1,
            "name": "FireworksSlowMotion",
            "thumbnail": "//cdn.brid.tv/live/partners/22479/thumb/936916_t_1641529313.png",
            "image": "//cdn.brid.tv/live/partners/22479/snapshot/936916.png",
            "source": {
              "ld": "//cdn.brid.tv/live/partners/22479/ld/936916.mp4",
              "sd": "//cdn.brid.tv/live/partners/22479/sd/936916.mp4",
              "hsd": "//cdn.brid.tv/live/partners/22479/hsd/936916.mp4",
              "hd": "//cdn.brid.tv/live/partners/22479/hd/936916.mp4"
            },
            "duration": 63,
            "width": 1920,
            "height": 1080,
            "monetize": true,
            "publish_time": "00:00:00",
            "publish": "2022-01-07",
            "age_gate_id": 1,
            "webp": "//cdn.brid.tv/live/partners/22479/webp/936916.webp",
            "liveimage": true,
            "thumb": "http://cdn.brid.tv/live/partners/22479/thumb/936916_t_1641529313.png",
            "tracks": [
              {
                "kind": "storyboard",
                "src": "//cdn.brid.tv/live/partners/22479/vtt/936916.vtt"
              }
            ]
          }
        },
        {
          "Video": {
            "id": 936915,
            "channel_id": 18,
            "ads": [
              []
            ],
            "encoded": true,
            "status": 1,
            "name": "FreeSmokeYoutube",
            "thumbnail": "//cdn.brid.tv/live/partners/22479/thumb/936915_t_1641529303.png",
            "image": "//cdn.brid.tv/live/partners/22479/snapshot/936915.png",
            "source": {
              "ld": "//cdn.brid.tv/live/partners/22479/ld/936915.mp4",
              "sd": "//cdn.brid.tv/live/partners/22479/sd/936915.mp4"
            },
            "duration": 15,
            "monetize": true,
            "publish_time": "00:00:00",
            "publish": "2022-01-07",
            "age_gate_id": 1,
            "webp": "//cdn.brid.tv/live/partners/22479/webp/936915.webp",
            "liveimage": true,
            "thumb": "http://cdn.brid.tv/live/partners/22479/thumb/936915_t_1641529303.png",
            "tracks": [
              {
                "kind": "storyboard",
                "src": "//cdn.brid.tv/live/partners/22479/vtt/936915.vtt"
              }
            ]
          }
        },
        {
          "Video": {
            "id": 935080,
            "channel_id": 121,
            "ads": [
              []
            ],
            "encoded": true,
            "status": 1,
            "name": "Microscope Sample",
            "description": "Testing using Microscope",
            "thumbnail": "//cdn.brid.tv/live/partners/22479/thumb/935080_t_1641231343.png",
            "image": "//cdn.brid.tv/live/partners/22479/snapshot/935080.png",
            "source": {
              "ld": "//cdn.brid.tv/live/partners/22479/ld/935080.mp4",
              "sd": "//cdn.brid.tv/live/partners/22479/sd/935080.mp4",
              "hsd": "//cdn.brid.tv/live/partners/22479/hsd/935080.mp4",
              "hd": "//cdn.brid.tv/live/partners/22479/hd/935080.mp4"
            },
            "duration": 17,
            "width": 1920,
            "height": 1080,
            "monetize": true,
            "publish_time": "00:00:00",
            "publish": "2022-01-03",
            "age_gate_id": 1,
            "webp": "//cdn.brid.tv/live/partners/22479/webp/935080.webp",
            "liveimage": true,
            "video_background": true,
            "clickthroughUrl": "relytv.com",
            "thumb": "http://cdn.brid.tv/live/partners/22479/thumb/935080_t_1641231343.png",
            "tracks": [
              {
                "kind": "storyboard",
                "src": "//cdn.brid.tv/live/partners/22479/vtt/935080.vtt"
              }
            ]
          }
        }
      ],
      "paging": {
        "Video": {
          "page": 1,
          "current": 4,
          "count": 4,
          "prevPage": false,
          "nextPage": false,
          "pageCount": 1,
          "order": {
            "Video.id": "desc"
          },
          "limit": 10,
          "options": [],
          "paramType": "querystring",
          "queryScope": null
        }
      }
    }