import React, {useState, useEffect} from 'react';
import { useParams, useHistory } from "react-router-dom";
import { API } from "aws-amplify";
import { onError } from "../../lib/errorLib";
import { useAppContext } from "../../lib/contextLib";

import "./userList.css";
import { DataGrid,  GridToolbar  } from "@mui/x-data-grid";
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
// import { userRows } from "../../dummyData";
import { Link } from "react-router-dom";

export default function UserList() {

  const history = useHistory();
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const handleDelete = (id) => {
    setData(data.filter((item) => item.id !== id));
  };
  
  function loadUsers() {
    return API.get("clientnamebackend", "/getusers");
  }


  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        history.push("/signin");
        return;
      }
  
      try {
        const userListDB = await loadUsers();
        let userListWithID = userListDB.map((usr) => {
          return {
            ...usr,
            id: usr.userName
          };
        });
        // console.log(userListWithID);
        setData(userListWithID);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(true);
        onError(e);
      }
        setIsLoading(false);
    }
  
    onLoad();
  }, [isAuthenticated]);

  const columns = [
    { field: "id", headerName: "ID", width: 200 },
    {
      field: "user",
      headerName: "User",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="userListUser">
            {params.row.userDisplayName}
          </div>
        );
      },
    },
    { field: "userEmail", headerName: "Email", width: 200 },
    { field: "userPlan", headerName: "Current Plan", width: 120 },
    { 
      field: "userPlanExpiry", 
      headerName: "Plan Expiry", 
      renderCell: (params) => {
        return (
          <>
            {(new Date(params.row.userPlanExpiry)).toISOString().slice(0, 10).replace("T", " ")}
          </>
        )
      },
      width: 150 
    },
    {
      field: "userStatus",
      headerName: "Status",
      renderCell: (params) => {
        return (
          <>
            {(params.row.userStatus)? "Active" : "Inactive"}
          </>
        )
      },
      width: 120,
    },
    {
      field: "userLastLoginAt",
      headerName: "Latest Login",
      width: 160,
      renderCell: (params) => {
        return (
          <>
            {(new Date(params.row.userLastLoginAt)).toISOString().slice(0, 10).replace("T", " ")}
          </>
        )
      },
    },
    {
      field: "userLastLoginIp",
      headerName: "Latest Login IP",
      width: 160,
    },
    {
      field: "action",
      headerName: "Action Mark-Inactive",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link to={"/user/" + params.row.userName}>
              <button className="userListEdit">Edit</button>
            </Link>
            <BlockOutlinedIcon
              className="userListDelete"
              onClick={() => handleDelete(params.row.userName)}
            />
          </>
        );
      },
    },
  ];

  return (
    <div className="userList">
      {/* <span className="userListHeader"><h2>User List</h2></span> */}
      <div className="userTitleContainer">
        <h1 className="userTitle">User List</h1>
        <Link to="/newUser">
          <button className="userAddButton">New Site User</button>
        </Link>
      </div>
      <DataGrid
        rows={data}
        disableSelectionOnClick
        columns={columns}
        rowsPerPageOptions={[10, 25, 50, 100]}
        // pageSize={10}
      // checkboxSelection
        components={{ Toolbar: GridToolbar }} 
      />
    </div>
  );
}
