import React, {useState, useEffect, useRef} from 'react';
// import { Link } from "react-router-dom";
import { API, Storage } from "aws-amplify";
import { useHistory } from "react-router-dom";

import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';

import {tagsData, genresData} from '../../dummyData';

import { s3Upload } from "../../lib/awsLib";
import { useAppContext } from "../../lib/contextLib";
import { onError } from "../../lib/errorLib";
import './settings.css';

export default function Settings() {

  const fileLogo = useRef(null);
  const fileFS = useRef(null);
  const [data, setData] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const [inputTagValue, setInputTagValue] = React.useState('')
  const [inputGenresValue, setInputGenresValue] = React.useState('')
  const [fileLogoURL, setFileLogoURL] = React.useState()
  const [fileFSURL, setFileFSURL] = React.useState()

  const handleTagChange = (event, newValue) => {
    setData(prev => {
      return {
        ...prev,
        channelTags: newValue
      }
    });
  };

  const handleGenresChange = (event, newValue) => {
      setData(prev => {
        return {
          ...prev,
          channelGenres: newValue
        }
      });
  };

  function handleLogoFileChange(event) {
    // console.log("🚀 ~ file: Settings.jsx ~ line 66 ~ handleLogoFileChange ~ event", event)
    fileLogo.current = event.target.files[0];
  }

  function handleFSFileChange(event) {
    fileFS.current = event.target.files[0];
  }

  async function updateChannel(channelData) {
    // console.log("call updateChannel with: ", channelData);
    return API.put("clientnamebackend", "/updatechannel/"+channelData.channelId, {
      body: {content: channelData}
    });
  }

  async function loadChannel() {
    return API.get("clientnamebackend", "/getchannel");
  }

  const handleChannelSave = async (event) => {
    event.preventDefault();

    if (fileFS.current && fileFS.current.size > 10000000) {
      alert( `Please pick a FS file smaller than 10MB.` );
      return;
    }

    if (fileLogo.current && fileLogo.current.size > 10000000) {
      alert( `Please pick a Logo file smaller than 10MB.` );
      return;
    }

    try {
      if (fileFS.current) {
        const attachmentFS = await s3Upload(fileFS.current);
        data.channelFlashScreenPath = attachmentFS;
      }
      if (fileLogo.current) {
        const attachmentLogo = await s3Upload(fileLogo.current);
        data.channelLogoPath = attachmentLogo;
      }

      data.channelId = data.channelId || 12345; // #TODO Fix this
      await updateChannel(data);
      history.push("/settings");
      setIsLoading(true);
    } catch (e) {
      setIsLoading(false);
      onError(e);
    }
    setIsLoading(true);
  };

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        history.push("/signin");
        return;
      }
  
      try {
        const allChannelData = await loadChannel();
        // console.log("🚀 ~ file: Settings.jsx ~ line 74 ~ onLoad ~ channelData", allChannelData)
        let channelData = allChannelData[0];  // #TODO Fix this
        setData(channelData);
        if (channelData?.channelFlashScreenPath) {
          // let fsURL = await Storage.get(channelData?.channelFlashScreenPath);
          let fsURL = await Storage.vault.get(channelData?.channelFlashScreenPath);
          // console.log("🚀 ~ file: Settings.jsx ~ line 134 ~ onLoad ~ fsURL", fsURL)
          setFileFSURL(fsURL);
        } 
        if (channelData?.channelLogoPath) {
          // let logoURL = await Storage.get(channelData?.channelLogoPath);
          let logoURL = await Storage.vault.get(channelData?.channelLogoPath);
          // console.log("🚀 ~ file: Settings.jsx ~ line 140 ~ onLoad ~ logoURL", logoURL)
          setFileLogoURL(logoURL);
        }
        setIsLoading(false);
      } catch (e) {
        setIsLoading(true);
        onError(e);
      }
        setIsLoading(false);
    }
  
    onLoad();
  }, [isAuthenticated]);

  return (
    <div className="settings">
      <div className="settingsTitleContainer">
        <h1 className="settingsTitle">Channel Settings</h1>
      </div>
      {data && (
        <div className="settingsContainer">
          <div className="settingsShow">
            <div className="settingsShowLeft">
              <img
                // src="http://cdn.brid.tv/live/partners/22479/thumb/936917_t_1641529350.png"
                src={fileLogoURL}
                alt=""
                className="settingsShowImg"
              />
              <div className="settingsShowLeftTitle">
                <span className="settingsShowUsername">{data.channelTitle}</span>
                <span className="settingsShowUserTitle">{data.channelURL}</span>
              </div>
            </div>
            <div className="settingsShowRight">
              <div className="settingsShowRightChannel">
                <span className="settingsShowTitle">Channel Details</span>
                <div className="settingsShowInfo">
                  <PermIdentityOutlinedIcon className="settingsShowIcon" />
                  <span className="settingsShowInfoTitle">{data.channelId}</span>
                </div>
                <div className="settingsShowInfo">
                  <CalendarTodayOutlinedIcon className="settingsShowIcon" />
                  <span className="settingsShowInfoTitle">
                    since  {data.channelCreatedAt && (new Date(data.channelCreatedAt)).toISOString().slice(0, 10).replace("T", " ")}
                  </span>
                </div>
              </div>
              <div className="settingsShowRightSocial">
                <span className="settingsShowTitle">Social Coordinates</span>
                <div className="settingsShowInfo">
                  <FacebookIcon className="settingsShowIcon" />
                  <span className="settingsShowInfoTitle">{data.channelFB}</span>
                </div>
                <div className="settingsShowInfo">
                  <InstagramIcon className="settingsShowIcon" />
                  <span className="settingsShowInfoTitle">{data.channelInsta}</span>
                </div>
                <div className="settingsShowInfo">
                  <TwitterIcon className="settingsShowIcon" />
                  <span className="settingsShowInfoTitle">{data.channelTwitter}</span>
                </div>
              </div>
              <div className="settingsShowRightContact">
                <span className="settingsShowTitle">Contact Details</span>
                <div className="settingsShowInfo">
                  <PhoneAndroidOutlinedIcon className="settingsShowIcon" />
                  <span className="settingsShowInfoTitle">{data.channelPhone}</span>
                </div>
                <div className="settingsShowInfo">
                  <MailOutlineOutlinedIcon className="settingsShowIcon" />
                  <span className="settingsShowInfoTitle">{data.channelEmail}</span>
                </div>
                <div className="settingsShowInfo">
                  <BusinessOutlinedIcon className="settingsShowIcon" />
                  <span className="settingsShowInfoTitle">{data.channelAddress}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="settingsUpdate">
            <span className="settingsUpdateTitle">Edit</span>
            <form className="settingsUpdateForm">
              <div className="settingsUpdateLeft">
                <div className="settingsUpdateItem">
                  <label>Channel Display Name</label>
                  <input
                    type="text"
                    placeholder="The Nature Channel"
                    value={data.channelTitle}
                    onChange={(e) => setData(prevState => ({...prevState, channelTitle: e.target.value}))}
                    className="settingsUpdateInput"
                  />
                </div>
                <div className="settingsUpdateItem">
                  <label>Support Email</label>
                  <input
                    type="text"
                    placeholder="contact@naturechannel.com"
                    value={data.channelEmail}
                    onChange={(e) => setData(prevState => ({...prevState, channelEmail: e.target.value}))}
                    className="settingsUpdateInput"
                  />
                </div>
                <div className="settingsUpdateItem">
                  <label>Website</label>
                  <input
                    type="text"
                    placeholder="www.naturechannel.com"
                    value={data.channelURL}
                    onChange={(e) => setData(prevState => ({...prevState, channelURL: e.target.value}))}
                    className="settingsUpdateInput"
                  />
                </div>
                <div className="settingsUpdateItem">
                  <label>Category</label>
                  <input
                    type="text"
                    placeholder="Travel"
                    value={data.channelCategory}
                    onChange={(e) => setData(prevState => ({...prevState, channelCategory: e.target.value}))}
                    className="settingsUpdateInput"
                  />
                </div>
                <div className="settingsUpdateItem">
                  <label>Support Phone</label>
                  <input
                    type="text"
                    placeholder="+1 123 456 67"
                    value={data.channelPhone}
                    onChange={(e) => setData(prevState => ({...prevState, channelPhone: e.target.value}))}
                    className="settingsUpdateInput"
                  />
                </div>
                <div className="settingsUpdateItem">
                  <label>Regd. Office Address</label>
                  <input
                    type="text"
                    placeholder="New York | USA"
                    value={data.channelAddress}
                    onChange={(e) => setData(prevState => ({...prevState, channelAddress: e.target.value}))}
                    className="settingsUpdateInput"
                  />
                </div>
                <div className="settingsUpdateItem">
                  <FacebookIcon  />
                <input
                    type="text"
                    placeholder="fb.com/nchannel"
                    value={data.channelFB}
                    onChange={(e) => setData(prevState => ({...prevState, channelFB: e.target.value}))}
                    className="settingsUpdateInput"
                  />
                </div>
                <div className="settingsUpdateItem">
                  <InstagramIcon  />
                <input
                    type="text"
                    placeholder="insta/@nchannel"
                    value={data.channelInsta}
                    onChange={(e) => setData(prevState => ({...prevState, channelInsta: e.target.value}))}
                    className="settingsUpdateInput"
                  />
                </div>
                <div className="settingsUpdateItem">
                  <TwitterIcon  />
                <input
                    type="text"
                    placeholder="@nchannel"
                    value={data.channelTwitter}
                    onChange={(e) => setData(prevState => ({...prevState, channelTwitter: e.target.value}))}
                    className="settingsUpdateInput"
                  />
                </div>
              </div>
              <div className="settingsUpdateRight">
                <div className="settingsUpdateItem">
                  <label>Channel Description</label>
                  <textarea
                    className="settingsUpdateInputTextArea"
                    placeholder="Provide detailed description about your channel"
                    value={data.channelDesc}
                    onChange={(e) => setData(prevState => ({...prevState, channelDesc: e.target.value}))}
                    rows="3" cols="100">
                  </textarea>
                </div>
                <div className="settingsUpdateItem">
                  <label>Genres</label>
                  <Autocomplete
                    multiple
                    id="tags-filled"
                    options={genresData}
                    freeSolo
                    limitTags={9}
                    value={data.channelGenres ? data.channelGenres : ['Loading...']}
                    onChange={handleGenresChange}
                    inputValue={inputGenresValue}
                    onInputChange={(_, newInputValue) => {
                      setInputGenresValue(newInputValue)
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // variant="standard"
                        // label="Genres"
                        placeholder="Add Genres..."
                      />
                    )}
                  />
                </div>
                <div className="settingsUpdateItem">
                  <label>Tags</label>
                  <Autocomplete
                    multiple
                    id="tags-filled"
                    options={tagsData}
                    freeSolo
                    limitTags={9}
                    value={data.channelTags ? data.channelTags : ['Loading...']}
                    onChange={handleTagChange}
                    inputValue={inputTagValue}
                    onInputChange={(_, newInputValue) => {
                      setInputTagValue(newInputValue)
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // variant="standard"
                        // label="Tags"
                        placeholder="Add Tags..."
                      />
                    )}
                  />
                </div>
                <div className="settingsUploadImagesSection">
                  <div className="settingsUpdateUpload">
                    <div className="settingsUpdateUploadImgLabel">
                      <label htmlFor="fileLogo" > Update Channel Logo </label>
                      <input type="file" id="fileLogo" onChange={handleLogoFileChange} />
                    </div>
                    <img className="settingsUpdateImgLogo"
                      // src="http://cdn.brid.tv/live/partners/22479/thumb/936917_t_1641529350.png"
                      // src={fileLogo ? URL.createObjectURL(fileLogo) : data.channelLogo}
                      src={fileLogoURL ? fileLogoURL : data.channelLogo}
                      alt="" />
                  </div>
                  <div className="settingsUpdateUpload">
                    <div className="settingsUpdateUploadImgLabel">
                      <label htmlFor="fileFS" > Update Channel FlashScreen </label>
                      <input type="file" id="fileFS" onChange={handleFSFileChange} />
                    </div>
                    <img className="settingsUpdateImgFlash"
                      // src="http://cdn.brid.tv/live/partners/22479/thumb/936917_t_1641529350.png"
                      // src={fileFS ? URL.createObjectURL(fileFS) : data.channelFlashScreen}
                      src={fileFSURL ? fileFSURL : data.channelFlashScreen}
                      alt="" />
                  </div>
                </div>
                <button className="settingsUpdateButton" onClick={handleChannelSave}>Update</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
