import React, {useState, useRef} from 'react';
import { API, Storage } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { onError } from "../../lib/errorLib";
import { s3Upload } from "../../lib/awsLib";
import { dbUpload, dbDownload } from "../../lib/dropboxLib";
import config from '../../config';

import { getSignedUrl }  from "@aws-sdk/s3-request-presigner";
import { S3Client, GetObjectCommand, S3ClientConfig } from "@aws-sdk/client-s3";

import "./addVideo.css";

export default function AddVideo() {
  const videoFile = useRef(null);
  const history = useHistory();
  const [videoTitle, setVideoTitle] = useState("");
  const [videoDesc, setVideoDesc] = useState("");

  function handleVideoFileChange(event) {
    videoFile.current = event.target.files[0];
  }

  const handleVideoUploadDB = async (event) => {
    event.preventDefault();

    if (!videoFile.current) {
      alert( `Please pick a Video file.` );
      return;
    }

    if (!videoTitle || !videoDesc) {
      alert( `Please provide both Title & Description.` );
      return;
    }

    if (videoFile.current && videoFile.current.size > 3000000000) {
      alert( `Please pick a Video file smaller than 3 GB.` );
      return;
    }

    try {
      let uploadedVideoURL;
      if (videoFile.current) {
        const uploadedVideoPath = await dbUpload(videoFile.current);
        
        if (!uploadedVideoPath) {
          throw new Error("Error while uploading video, please contact admin");
        }
        uploadedVideoURL = await dbDownload(uploadedVideoPath);
        if (!uploadedVideoURL) {
          throw new Error("Error while uploading video, please contact admin");
        }
      }

      let uploadVideoStatus = await API.post("clientnamebackend", "/addVideoToBrid", {
        body: {
          name: videoTitle || "Unnamed video",
          description: videoDesc || "No description available",
          mp4: uploadedVideoURL || "http://cdn.brid.tv/uid/partners/12579/sd/540625.mp4"  // < thisworks..
        }
      });
      
      history.push("/allvideos");
      // setIsLoading(true);
    } catch (e) {
      // setIsLoading(false);
      onError(e);
    }
    // setIsLoading(true);
  };

  return (
    <div className="addVideo">
      <div className="addVideoContainer">
        <h1 className="addVideoTitle">Add New Video</h1>
        <form className="addVideoForm">
          <div className="addVideoItem">
            <label>Title</label>
            <input 
              type="text" 
              placeholder="New Title of Video"
              value={videoTitle}
              onChange={(e) => setVideoTitle(e.target.value)}
            />
          </div>
          <div className="addVideoItem"> 
            <label>Description</label>
            <input 
              type="text"
              placeholder="Description of the video"
              value={videoDesc}
              onChange={(e) => setVideoDesc(e.target.value)}
            />
          </div>
          <div className="uploadVideoSection">
            <div className="updateUpload">
              <div className="updateUploadImgLabel">
                <label htmlFor="videoFile" > Select video </label>
                <input type="file" id="videoFile" onChange={handleVideoFileChange} />
              </div>
            </div>
          </div>
          <button className="addVideoButton" onClick={handleVideoUploadDB} >Upload Video</button>
        </form>
      </div>
    </div>
  );
}
