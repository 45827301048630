import React from "react";
import "./topbar.css";
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

export default function Topbar() {
  return (
    <div className="topbar">
      <div className="topbarWrapper">
        <div className="topLeft">
          <span className="logo">RelyTV CMS</span>
        </div>
        <div className="topRight">
          <div className="topbarIconContainer">
            <NotificationsNoneOutlinedIcon />
            <span className="topIconBadge">2</span>
          </div>
          <div className="topbarIconContainer">
            <SettingsOutlinedIcon />
          </div>
          <img src="https://cdn.pixabay.com/photo/2014/04/03/11/47/avatar-312160_960_720.png" alt="" className="topAvatar" />
        </div>
      </div>
    </div>
  );
}
