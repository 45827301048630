import React, {useState, useEffect} from 'react';
import { API } from "aws-amplify";
import { useHistory } from "react-router-dom";

import { useAppContext } from "../../lib/contextLib";
import { onError } from "../../lib/errorLib";

import Chart from "../../components/chart/Chart";
import CustomPieChart from '../../components/chart/CustomPieChart';
import TopTen from "../../components/topten/TopTen";
import FeaturedInfo from "../../components/featuredInfo/FeaturedInfo";

import "./home.css";

export default function Home() {
  // Function to get the New & Recent list of videos
  function getMetricsVideos() {
    // console.log("getMetricsVideos() -> /getallvideos");
    return API.get("clientnamebackend", "/getallvideos");
  }

  function getAdMetrics() {
    // console.log("getAdMetrics() -> /getAdMetrics");
    return API.get("clientnamebackend", "/getAdMetrics");
  }
  function getVidMetrics() {
    // console.log("getVidMetrics() -> /getVidMetrics");
    return API.get("clientnamebackend", "/getVideoMetrics");
  }
  // function dailyMetricsUpdate() {  // This code has been moved to scheduler/cron
  //   console.log("dailyMetricsUpdate() -> /dailyMetricsUpdate");
  //   return API.get("clientnamebackend", "/dailyMetricsUpdate");
  // }


  const formatPieChartData = (data) => {
    const outArr =  Object.keys(data).map(key => ({ name: key, value: data[key]}));
    // console.log("Formatted PIE ", outArr);
    return outArr;
  };

  function sortDailyNumber (a, b) {
    if ( a["name"] < b["name"] ){
      return -1;
    }
    if ( a["name"] > b["name"] ){
      return 1;
    }
    return 0;
  }

  const updateDailyNumbers = (dailyNumbers, vidNumbersObj) => {
    // console.log("Inside updateDailyNumbers, rcd vidObj => ", vidNumbersObj);
    const outObj = {};
    outObj.Monthly = [];
    outObj.Total = {
      "AdReq": 0,
      "AdImp": 0,
      "AdErr": 0,
      "AdComp": 0,
      "fillRate": 0.0,
      "AdRevenue": 0.0,
      "View": 0,
      "VidPlays": 0,
      "VidComps": 0,
      "Total": 0,
      "currMonthString": "Month"
    }
    Object.keys(dailyNumbers).map(elem => {
      const obj = {};
      obj.name = elem;
      const currVidPlay = (vidNumbersObj?.[elem]?.["Play"]) ? vidNumbersObj[elem]["Play"] : 0;
      const currVidComp = (vidNumbersObj?.[elem]?.["100%"]) ? vidNumbersObj[elem]["100%"] : 0;
      obj["AdReq"] = dailyNumbers[elem]["AdReqs"];
      obj["AdImp"] = dailyNumbers[elem]["AdImps"];
      obj["AdErr"] = dailyNumbers[elem]["AdErrs"];
      obj["AdComp"] = dailyNumbers[elem]["AdComp"];
      obj["fillRate"] = dailyNumbers[elem]["AdFillRate"];
      obj["AdRevenue"] = dailyNumbers[elem]["AdRevenue"];
      obj["VidPlays"] = (vidNumbersObj?.[elem]?.["Play"]) ? vidNumbersObj[elem]["Play"] : 0;
      obj["VidComps"] = (vidNumbersObj?.[elem]?.["100%"]) ? vidNumbersObj[elem]["100%"] : 0;
      // obj["View"] = 0; // # TODO - fix this
      obj["Total"] = 0; // # TODO - fix this
      outObj.Monthly.push(obj);

      const elemDate = new Date(elem);
      const elemMonth = elemDate.getMonth() + 1
      const currDate = new Date();
      const currMonth = currDate.getMonth() + 1
      const currMonthString = currDate.toLocaleString('default', { month: 'long' });
      outObj["Total"]["currMonthString"] = currMonthString;
      if (elemMonth === currMonth) { // Add to totals only values of current month. Remaining values used for chart
        outObj["Total"]["AdReq"] = outObj["Total"]["AdReq"] + dailyNumbers[elem]["AdReqs"] || 0;
        outObj["Total"]["AdImp"] = outObj["Total"]["AdImp"] + dailyNumbers[elem]["AdImps"] || 0;
        outObj["Total"]["AdErr"] = outObj["Total"]["AdErr"] + dailyNumbers[elem]["AdErrs"] || 0;
        outObj["Total"]["AdComp"] = outObj["Total"]["AdComp"] + dailyNumbers[elem]["AdComp"] || 0;
        const currRev = parseFloat((dailyNumbers[elem]["AdRevenue"] || 0).toFixed(2));
        outObj["Total"]["AdRevenue"] = parseFloat((outObj["Total"]["AdRevenue"] + currRev).toFixed(2));
        outObj["Total"]["VidPlays"] = outObj["Total"]["VidPlays"] + currVidPlay;
        outObj["Total"]["VidComps"] = outObj["Total"]["VidComps"] + currVidComp;
        outObj["Total"]["fillRate"] = (outObj["Total"]["AdReq"] > 0) ? parseFloat((((outObj["Total"]["AdImp"]  * 100) / outObj["Total"]["AdReq"])).toFixed(2)) : 0.0;
      }
    });
    const sortedMonthly = outObj.Monthly.sort(sortDailyNumber);
    outObj.Monthly = sortedMonthly;
    // console.log({outObj})
    return outObj;
  };
  
  const [adMetricsData, setAdMetricsData] = useState({});
  const [vidMetricsData, setVidMetricsData] = useState({});
  const { isAuthenticated } = useAppContext();
  const history = useHistory();

  useEffect(() => {
    // console.log('useEffect called in Home.jsx')
    async function onLoad() {
        if (!isAuthenticated) {
            history.push("/signin");
            return;
        }

        try {
            // let dailyMetricsUpdateResult = await dailyMetricsUpdate(); // moveD to cron/scheduler
            // console.log("useEffect() -> with dailyMetricsUpdateResult:::", dailyMetricsUpdateResult);
            let vidMetricsDataResp = await getVidMetrics();
            // console.log("useEffect() -> with getVidMetricsResult:::", vidMetricsDataResp);
            let adMetricsDataResp = await getAdMetrics();
            // console.log("useEffect() -> with getAdMetricsResult:::", adMetricsDataResp);
            adMetricsDataResp["updatedDailyNumbers"] = updateDailyNumbers(adMetricsDataResp.adDailyNumbers, vidMetricsDataResp?.videoDailyNumbersObj);
            // console.log("useEffect() -> CHK adMetricsDataResp > updatedDailyNumbers:::", adMetricsDataResp);
            
            setVidMetricsData(vidMetricsDataResp);
            setAdMetricsData(adMetricsDataResp);
          } catch (e) {
            onError(e);
        }

        // setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated]);


  return (
    <div className="home">
      { (adMetricsData.updatedDailyNumbers?.Monthly?.length > 0) &&  <FeaturedInfo dbData={adMetricsData.updatedDailyNumbers}/>}
      <div className="homeWidgets">
        <div className="homeChart">
          <Chart data={adMetricsData.updatedDailyNumbers?.Monthly || []} title="Channel Analytics" grid dataKey={["VidPlays", "AdReq" , "AdImp", "AdRevenue"]}/>
        </div>
        <div className="homePie">
          { vidMetricsData.osData && <CustomPieChart title="Operating System" data={ formatPieChartData(vidMetricsData.osData) }/>}
        </div>
        <div className="homePie">
        { vidMetricsData.osData && <CustomPieChart title="Browser" data={ formatPieChartData(vidMetricsData.browserData) }/> }
        </div>
      </div>
      <div className="homeWidgets">
        <TopTen title="Most Played Videos" sortedData={vidMetricsData.top10VideoPlays} dataKey="plays"/>
        <TopTen title="Top Tags by CPM" sortedData={adMetricsData.top10AdCPM} dataKey="AdCPM"/>
      </div>
      <div className="homeWidgets">
        <TopTen title="Top Tags by Revenue" sortedData={adMetricsData.top10AdRevenue} dataKey="AdRevenue"/>
        <TopTen title="Top Tags by Fill Rate" sortedData={adMetricsData.top10AdFillRates} dataKey="AdFillRate"/>
      </div>
      <div className="homeWidgets">
        <TopTen title="Top Tags by Ad Reqs" sortedData={adMetricsData.top10AdRequests} dataKey="AdReqs"/>
        <TopTen title="Top Tags by Ad Imps" sortedData={adMetricsData.top10AdImps} dataKey="AdImps"/>
      </div>
      <div className="homeWidgets">
        <TopTen title="Top Tags by Ad Errors" sortedData={adMetricsData.top10AdErrors} dataKey="AdErrs"/>
        <TopTen title="Top Tags by Ad Complete" sortedData={adMetricsData.top10AdComp} dataKey="AdComp"/>
      </div>
    </div>
  );
}
