import React, {useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { API } from "aws-amplify";
import { onError } from "../../lib/errorLib";
import { useAppContext } from "../../lib/contextLib";
import { dbUpload, dbDownload } from "../../lib/dropboxLib";

import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import Chart from "../../components/chart/Chart"
import "./videoSingle.css";
import {tagsData, genresData} from "../../dummyData";  // #TODO #FIXME populate with actual data

export default function VideoSingle() {

    const history = useHistory();
    const videoImageRef = useRef(null);

    let { videoId } = useParams();
    videoId = parseInt(videoId)
    // console.log('Recvd id in single=', videoId)

    const { isAuthenticated } = useAppContext();
    const [dbData, setDbData] = useState({});
    const [dbChartData, setDbChartData] = useState({});
    const [categoryValue, setCategoryValue] = useState(dbData.videoCategory || 'UnAssorted');
    const [serSeaDisabled, setSerSeaDisabled] = useState((dbData.videoCategory === 'Movie' || dbData.videoCategory === 'ShortFilm') ? true : false);
    const [epiNumDisabled, setEpiNumDisabled] = useState((dbData.videoCategory === 'Movie' || dbData.videoCategory === 'ShortFilm') ? true : false);
    const [epiNum, setEpiNum] = React.useState(dbData.videoEpisode || 0);
    const [tags, setTags] = React.useState(dbData.videoTags || []);
    const [genres, setGenres] = React.useState(dbData.videoGenres || []);
    const [partner, setPartner] = React.useState(dbData.videoPartner || '');
    const [featured, setFeatured] = React.useState(dbData.videoFeatured || false);
    const [serSeaValue, setSerSeaValue] = React.useState({season: dbData.videoSeason, series: dbData.videoSeries, seriesId: dbData.videoSeriesId } || {});
    const [serSeaInputValue, setSerSeaInputValue] = React.useState('');
    const [serSeaOptions, setSerSeaOptions] = React.useState([]);

    function loadVideo() {
        // console.log('loadVideo called with videoId = ', videoId)
        return API.get("clientnamebackend", "/getvideos/" + videoId);
    }

    function getMetricsData() {
        // return API.get("clientnamebackend", "/getallmetrics/6/" + videoId);
        return API.get("clientnamebackend", "/getEachVideoMetrics/" + videoId);
    }
    
    function getSerSeaData() {
        // console.log('getSerSeaData called')
        return API.get("clientnamebackend", "/getseries");
    }

    async function getExistingEpiNumsFromDB(ser, sea) {
        // console.log('getSerSeaData called')
        if (!ser || !sea) {
            return [];
        }
        const paramsObj = {};
        if (ser !== '-') {
            paramsObj.series = encodeURIComponent(ser);
        }
        if (sea !== '-') {
            paramsObj.season = encodeURIComponent(sea);
        }

        const searchParams = new URLSearchParams(paramsObj);
        const params = searchParams.toString();
        // console.log('getSerSeaData params = ', params)
        return API.get("clientnamebackend", "/getExistingEpiNums?" + params);
    }

    function getSerSeaOptions(data) {
        // console.log('getSerSeaOptions received data = ', data)
        let options = [];
        options.push({season: '-', series: '-'});
        data.forEach(item => {
            item.seriesSeasons.forEach(season => {
                options.push({
                    season: season,
                    series: item.seriesTitle,
                    seriesId: item.seriesId,
                    seriesDesc: item.seriesDesc,
                });
            })
        })
        // console.log('getSerSeaOptions returning options = ', options)
        return options;
    }

    let processedDetailData = (inpData) => {
        // console.log("INSIDE processedDetailData with input = ", inpData);
      return Object.keys(inpData).map((dateKey, ix) => {
        let outObj = {};
        outObj.id = ix;
        outObj.name = dateKey;
        outObj.played = inpData[dateKey]["Play"] || 0;
        outObj.completed = inpData[dateKey]["100%"] || 0;
        // console.log({outObj})
        return outObj;
      });
    }

    useEffect(() => {
        // console.log('useEffect called')
        async function onLoad() {
            if (!isAuthenticated) {
                history.push("/signin");
                return;
            }

            try {
                const video = await loadVideo();
                // console.log('Recvd video = ', video)
                setDbData(video);
                setCategoryValue(video.videoCategory || 'UnAssorted');
                setSerSeaValue({season: video.videoSeason, series: video.videoSeries, seriesId: video.videoSeriesId} || {})
                setEpiNum(video.videoEpisode || 0);
                setTags(video.videoTags || []);
                setGenres(video.videoGenres || []);
                setPartner(video.videoPartner || '');
                setFeatured(video.videoFeatured || false);
                setSerSeaDisabled((video.videoCategory === 'Movie' || video.videoCategory === 'ShortFilm') ? true : false);
                setEpiNumDisabled((video.videoCategory === 'Movie' || video.videoCategory === 'ShortFilm') ? true : false);
                const serSeaData = await getSerSeaData();
                // console.log('Recvd serSeaData = ', serSeaData)
                const processedSerSeaOptions = getSerSeaOptions(serSeaData);
                // console.log('processedSerSeaOptions = ', processedSerSeaOptions)
                setSerSeaOptions(processedSerSeaOptions);

                let metricsData = await getMetricsData();
                // console.log('RAW metricsData eachVideoDetails= ', metricsData)
                // console.log('metricsData?.detailData= ', metricsData?.detailData)
                if (metricsData?.detailData &&  Object.keys(metricsData.detailData).length > 0) {
                    // console.log('Object.keys(metricsData.detailData)= ', Object.keys(metricsData?.detailData))
                    metricsData.processedDetailData = processedDetailData(metricsData.detailData);
                    // console.log('processed metricsData eachVideoDetails= ', metricsData)
                }
                setDbChartData(metricsData);
                
            } catch (e) {
                onError(e);
            }

            // setIsLoading(false);
        }

        onLoad();
    }, [isAuthenticated, videoId]);
    

    async function updateVideo(data) {
        // console.log("call updateVideo with: ", data);
        return API.put("clientnamebackend", `/updatevideos/${data.id}`, {
            body: {data}
        });
    }
    async function handleSubmit(event) {
        event.preventDefault();

        if (videoImageRef.current && videoImageRef.current.size > 10000000) {
            alert( `Please pick a Video image file smaller than 10MB.` );
            return;
        }
        
        let updatedVideoImageURL;
        try {
            if (videoImageRef.current) {
                const uploadedVideoPath = await dbUpload(videoImageRef.current);
                if (!uploadedVideoPath) {
                    throw new Error("Error while uploading Image, please contact admin");
                }
                updatedVideoImageURL = await dbDownload(uploadedVideoPath);
                if (!updatedVideoImageURL) {
                    throw new Error("Error while uploading video, please contact admin");
                }

                let updateVideoStatus = await API.post("clientnamebackend", "/updateVideoInBrid", {
                    body: {
                        videoId: videoId,
                        videoImageURL: updatedVideoImageURL
                    }
                });
                if (!updateVideoStatus) {
                    alert( `Error in uploading Video Image, aborting update` );
                    return;
                } else {
                    // console.log("Video update success", updateVideoStatus);
                    updatedVideoImageURL = updateVideoStatus.data?.Video?.image || "";
                }
            }

            const data = {
                id: dbData.id,
                videoCategory: categoryValue,
                // videoEpisode: epiNum,
                videoTags: tags,
                videoGenres: genres,
                videoPartner: partner,
                videoFeatured: (featured ? 1 : 0),
                // videoSeason: serSeaValue.season,
                // videoSeries: serSeaValue.series,
                // videoSeriesId: serSeaValue.seriesId,
            };
            if (categoryValue === "Series") {
                // check if provided episode number already exist for the Series / Season
                if (serSeaValue.series && serSeaValue.season) {
                    const existingEpiNums = await getExistingEpiNumsFromDB(serSeaValue.series, serSeaValue.season);
                    // console.log('existingEpiNums = ', existingEpiNums);

                    // check existingEpiNums of format {videoEpisode: 1, videoId: "1234"}
                    // if current videoId is getting updated with same epiNum, then it is ok
                    // if current videoId is getting updated with different epiNum, then it is ok
                    // if epinum is already present for some other videoId, then it is not ok
                    const existingEpiNumsInConflict = existingEpiNums.filter((epiNumObj) => {
                        if (epiNumObj.videoEpisode === epiNum) {
                            if (epiNumObj.videoId === videoId) {
                                return false;
                            } else {
                                return true;
                            }
                        } else {
                            return false;
                        }
                    });
                    // console.log('existingEpiNumsInConflict = ', existingEpiNumsInConflict);
                    if (existingEpiNumsInConflict.length > 0) {
                        alert( `Episode number ${epiNum} already exists for Series: ${serSeaValue.series} and Season: ${serSeaValue.season}. Please provide a different episode number.` );
                        return;
                    }
                } else {
                    alert( `Please provide Series and Season for the video.` );
                    return;
                }

                data.videoEpisode = epiNum;
                data.videoSeason = serSeaValue.season;
                data.videoSeries = serSeaValue.series; //#TODO check this warning
                data.videoSeriesId = serSeaValue.seriesId;
            } else  {
                data.videoEpisode = 0;
                data.videoSeason = "";
                data.videoSeries = "";
                data.videoSeriesId = "";
            }

            if (updatedVideoImageURL) {
                data.videoImage = updatedVideoImageURL;
            }
            await updateVideo(data);
            history.push("/allvideos");
        } catch (e) {

            // setIsLoading(false);
            onError(e);
        }
        // setIsLoading(true);
    }
    
    const handleVideoImageChange = (event) => {
        videoImageRef.current = event.target.files[0];
    }

    const handleCategoryChange = (event) => {
        const newCategory = event.target.value;
        // Enable Series & Season field and Epi num only if the category is unassorted or series
        if (newCategory === 'UnAssorted' || newCategory === 'Series') {
            setSerSeaDisabled(false);
            setEpiNumDisabled(false);
        } else {
            setSerSeaDisabled(true);
            setEpiNumDisabled(true);
        }
        setCategoryValue(event.target.value);
    };

    const handleEpiNumChange = (event) => {
        const newEpiNum = parseInt(event.target.value);
        if (newEpiNum < 1) {
            alert( `Episode Number should be positive` );
            return;
        }
      setEpiNum(parseInt(event.target.value));
    };

    const handleTagChange = (event, newValue) => {
        // console.log('handleGenresChange called with newValue = ', newValue)
        // if (event?.target?.textContent) {
        //     setTags([...tags, event.target.textContent]);
        // }
        setTags(newValue);
  };

    const handleGenresChange = (event, newValue) => {
        // console.log('handleGenresChange called with newValue = ', newValue)
        // if (event?.target?.textContent) {
        //     setGenres([...genres, event.target.textContent]);
        // }
        setGenres(newValue);
    };

    const handlePartnerChange = (event) => {
      setPartner(event.target.value);
    };

    const handleFeaturedChange = (event) => {
      setFeatured(event.target.checked);
    };

  return (
    <div className="videoSingle">
      <div className="videoSingleTitleContainer">
        <h1 className="videoSingleTitle">{dbData.videoCategory || 'UnAssorted'} - {dbData.videoTitle}</h1>
        {/* <Link to="/newproduct">
          <button className="videoSingleAddButton">Create</button>
        </Link> */}
      </div>
      <div className="videoSingleTop">
          <div className="videoSingleTopLeft">
              {/* <Chart data={videoData} dataKey={["Views"]} title="Video Views"/> */}
              <Chart data={dbChartData.processedDetailData} title="Video Analytics" dataKey={["played", "completed"]}/>
          </div>
          <div className="videoSingleTopMiddle">
            <img className="videoSingleCenterImg" src={dbData.videoImage} alt=""/>
          </div>
          <div className="videoSingleTopRight">
              <div className="videoSingleInfoTop">
                  <img src={dbData.videoThumbNail} alt="" className="videoSingleInfoImg" />
                  <span className="videoSingleName">{dbData.videoTitle}</span>
              </div>
              <div className="videoSingleInfoBottom">
                  <div className="videoSingleInfoItem">
                      <span className="videoSingleInfoKey">Video Id:</span>
                      <span className="videoSingleInfoValue">{dbData.id}</span>
                  </div>
                  <div className="videoSingleInfoItem">
                      <span className="videoSingleInfoKey">Description:</span>
                      <span className="videoSingleInfoValue"> {dbData.videoDesc || 'No additional data'}</span>
                  </div>
                  <div className="videoSingleInfoItem">
                      <span className="videoSingleInfoKey">Status:</span>
                      <span className="videoSingleInfoValue">{(dbData.videoStatusSrc) ? "Active" : "Inactive"}</span>
                  </div>
                  <div className="videoSingleInfoItem">
                    <span className="videoSingleInfoKey">Duration:</span>
                    <span className="videoSingleInfoValue">{dbData.videoDuration} secs</span>
                </div>
              </div>
          </div>
      </div>
      <div className="videoSingleBottom">
        <div className="videoSingleBottomNonEdit">
            <div className="videoSingleNonEditItem">
                <span className="videoSingleNonEditKey">Total Plays:</span>
                <span className="videoSingleNonEditValue">{dbChartData?.["summaryData"]?.["Play"] || 0}</span>
            </div>
            <div className="videoSingleNonEditItem">
                <span className="videoSingleNonEditKey">Completed plays:</span>
                <span className="videoSingleNonEditValue"> {dbChartData?.["summaryData"]?.["100%"] || 0}</span>
            </div>
            <div className="videoSingleNonEditItem">
                <span className="videoSingleNonEditKey">Completion %:</span>
                <span className="videoSingleNonEditValue"> {dbChartData?.["summaryData"]?.["Completion %"] || 0}</span>
                {/* <span className="videoSingleNonEditValue">
                    <Rating name="read-only" precision={0.1} value={dbData.videoTotalRating / (dbData.videoTotalRaters || 1)} readOnly />
                </span> */}
            </div>
            {/* <div className="videoSingleNonEditItem">
                <span className="videoSingleNonEditKey">Channel ID:</span>
                <span className="videoSingleNonEditValue">{dbData.channel_id || 'Remove'}</span>
            </div> */}
            <div className="videoSingleNonEditItem">
                <span className="videoSingleNonEditKey">Age Gate:</span>
                <span className="videoSingleNonEditValue">{(parseInt(dbData.videoAgeGateId) === 0) ? 'Everyone' : (parseInt(dbData.videoAgeGateId) === 1) ? '17+' : '18+'}</span>
            </div>
            <div className="videoSingleNonEditItem">
                <span className="videoSingleNonEditKey">Publish Date:</span>
                <span className="videoSingleNonEditValue">{new Date(dbData.videoPublish).toLocaleDateString('en-us', { year:"numeric", month:"short", day:"numeric"})}</span>
            </div>
        </div>
        <div className="videoSingleBottomEditable">
            <FormControl>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12} sm={12} lg={6}>
                            <RadioGroup
                                row
                                aria-labelledby="category-radio-buttons-group-label"
                                name="category-radio-buttons-group"
                                value={categoryValue}
                                onChange={handleCategoryChange}
                            >
                                <FormControlLabel labelPlacement="top" value="Movie" control={<Radio color='warning'  />} label="Movie" />
                                <FormControlLabel labelPlacement="top" value="ShortFilm" control={<Radio color='warning'  />} label="ShortFilm" />
                                <FormControlLabel labelPlacement="top" value="Series" control={<Radio color='warning'  />} label="Series" />
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={6} sm={6} lg={6}>
                           <FormControlLabel labelPlacement="start" id="featured" checked={featured} onChange={handleFeaturedChange} control={<Switch color='warning'/>} label="Featured?" />
                        </Grid>
                        <Grid item xs={12} sm={12} lg={6}>
                            <Autocomplete
                                id="grouped-ser-epi"
                                value = {serSeaValue}
                                disabled = {serSeaDisabled}
                                onChange={(event, newValue) => {
                                //   console.log('serSeaValue=', serSeaValue)
                                //   console.log('newValue=', newValue)
                                  setSerSeaValue(newValue);
                                }}
                                inputValue={serSeaInputValue}
                                onInputChange={(event, newInputValue) => {
                                  setSerSeaInputValue(newInputValue);
                                }}
                                options={serSeaOptions.sort((a, b) => -b.series.localeCompare(a.series))}
                                groupBy={(option) => option.series}
                                getOptionLabel={(option) => option.season + ` / ${option.series}`}
                                // isOptionEqualToValue={(option, value) => option.season === value.season}
                                isOptionEqualToValue={(option, value) => ((option.season === value.season) && (option.series === value.series) && (option.seriesId === value.seriesId))}
                                // sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label="Series & Season" />}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} lg={6}>
                            <TextField label="Ep#" id='epiNum' disabled={epiNumDisabled} value={epiNum} onChange={handleEpiNumChange} type='number' />
                            {/* <TextField label="Ep#" value={epiNum} onChange={handleEpiNumChange} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} /> */}
                        </Grid>
                        <Grid item xs={12} sm={12} lg={6}>
                            <Autocomplete
                                multiple
                                id="tags-standard"
                                options={tagsData}
                                // defaultValue={[tagsData[0]]}
                                freeSolo
                                limitTags={3}
                                value={tags}
                                onChange={handleTagChange}
                                // getOptionLabel={(option) => option}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                      <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                    ))
                                  }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        // variant="standard"
                                        label="Tags"
                                        placeholder="Add Tags..."
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} lg={6}>
                            <Autocomplete
                                multiple
                                limitTags={3}
                                value={genres}
                                onChange={handleGenresChange}
                                id="genres-standard"
                                options={genresData}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                    <TextField
                                    // variant="outlined"
                                    {...params}
                                    label="Genres"
                                    placeholder="Add Genres..."
                                />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} lg={6}>
                            {/* <FormControlLabel labelPlacement="start" control={<TextField id="standard-basic" label="Partner" variant="standard" />} label="Partner" /> */}
                            <TextField  fullWidth id="partner-name" value={partner} onChange={handlePartnerChange} label="Partner" variant="outlined" />
                        </Grid>
                        <Grid item xs={12} sm={12} lg={6}>
                            <ButtonGroup fullWidth size="large" color='warning' aria-label="large button group">
                                <Button key="btnReset">Reset</Button>
                                <Button key="btnSubmit" onClick={handleSubmit} >Submit</Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </Box>
            </FormControl>
        </div>
      </div>
      <div className="videoSingleBottomURL">
          <div className="videoSingleURLLeft">
              <div className="videoSingleInfoItem">
                    <span className="videoSingleInfoKey">URL:</span>
                    <span className="videoSingleInfoValue">{dbData.videoWebp}</span>
              </div>
          </div>
          <div className="videoSingleURLRight">
              <div className="videoSingleInfoItem">
                    <span className="videoSingleInfoKey">
                        <label htmlFor="videoImageRef" > Update Video Image </label>
                    </span>
                    {/* <span className="videoSingleInfoValue">{dbData.videoImage}</span> */}
                    <input type="file" id="videoImageRef" onChange={handleVideoImageChange} />
              </div>
          </div>
      </div>
    </div>
  );
}
